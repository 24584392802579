<template>
    <div class="py-16 w-full">
        <div class="container mx-auto">
            <h2 class="text-3xl font-bold text-center mb-12">Why Choose Review My eLearning?</h2>
            <div class="grid md:grid-cols-2 gap-8">
                <div class="bg-white p-8 rounded-lg shadow-lg">
                    <h3 class="text-2xl font-semibold mb-4">Revolutionize Your Review Process</h3>
                    <ul class="space-y-4">
                        <li v-for="(feature, index) in features" :key="index" class="flex items-center">
                            <font-awesome-icon icon="fa-regular fa-circle-check" class="text-green-600 mr-2" />
                            <span>{{ feature }}</span>
                        </li>
                    </ul>
                </div>
                <div class="bg-fuchsia-700 text-white p-8 rounded-lg shadow-lg flex flex-col">
                    <h3 class="text-2xl font-semibold mb-4">Our AI Advantage</h3>
                    <p class="mb-4">
                        Our advanced AI technology analyzes your courses for instructional design quality,
                        accessibility, user experience, and more. Get instant, actionable feedback to improve your
                        eLearning content.
                    </p>
                    <p class="font-bold mb-4 grow">
                        Save time, reduce errors, and deliver higher quality courses with Review My eLearning.
                    </p>
                    <router-link to="/join"
                        class="w-1/2 mx-auto text-center self-center rounded-full bg-[#2196F3] py-3 px-6 text-sm font-semibold text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                        Get started today
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const features = ref([
    "AI-Powered Course Analysis",
    "Watch recordings or Reviews as they navigate your course",
    "Seamless Integration with Major Authoring Tools",
    "Simplify your workflow and maintain consistency with the tools you already use",
    "Customizable Review Workflows",
    "Real-time Collaboration",
    "Comment Tracking and Exporting",
])
</script>